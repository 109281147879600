<template>
  <div>
    <div class="block mb-3">
      <div class="row">
        <div class="col-md-8">
          <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators" id="news-carousel-indicators">
              <li data-target="#carouselExampleIndicators" v-for="(pic, index) in newsPictureList" :key="index" data-slide-to="0" v-bind:class="[index === 0 ? 'active' : '']"></li>
              <!-- <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> -->
            </ol>
            <div class="carousel-inner" id="news-carousel">
              <div class="carousel-item" v-for="(pic, index) in newsPictureList" :key="pic" v-bind:class="[index === 0 ? 'active' : '']">
                <img class="d-block w-100" :src="pic" :style="{ height: isMobile ? '200px' : '330px' }">
              </div>
              <!-- <div class="carousel-item active">
                <img class="d-block w-100" src="img/pic1-c.jpg" style="height: 300px" alt="First slide">
              </div> -->
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div class="col-md-4">
          <div id="qr">
            <div class="d-flex flex-column justify-content-center align-items-center" style="height: 70%">
              <img class="img-fluid" src="@/assets/qr.png" style="width: 50%; height: 50%; object-fit: contain" />
              <span>微信扫一扫，使用小程序</span>
            </div>
            <div class="d-flex flex-column" style="height: 30%;">
              <div class="verification-container h-100">
                <span class="">合格证编号真伪查询</span>
                <div class="input-group mb-1">
                  <input type="text" class="form-control" placeholder="请输入合格证编号" v-model="certNo">
                  <div class="input-group-append">
                    <button class="btn btn-success" :disabled="!certNo" @click="verify()">查询</button>
                  </div>
                </div>
                <div v-if="verifyResult === 0">
                  <span class="text-small mr-4">检查结果：<span class="text-danger">失败</span></span>
                  <span class="text-small">合格证编号不存在</span>
                </div>
                <div v-else-if="verifyResult === 1">
                  <span class="text-small mr-4">检查结果：<span class="text-success">通过</span></span>
                  <span class="text-small">查询次数：{{ verifyNumber }}</span>
                </div>
                <div v-else>
                  <span class="text-small">合格证编号示例：20201018232548013116</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-0 mb-3">
      <div class="col-md-8 block">
        <div class="block-header">
          <span class="block-header-text">时事新闻</span>
        </div>
        <div class="">
          <ul class="list-unstyled" id="news">
            <li class="d-flex justify-content-between news" v-for="news in newsList" :key="news.id">
              <a class="news-link text-nong text-truncate" href @click="openNews(0, news.id, $event)">{{news.title}}</a>
              <span class="text-muted text-nowrap">[{{news.displayDate}}]</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 block">
        <div class="block-header">
          <span class="block-header-text">政务通知</span>
        </div>
        <ul class="list-unstyled" id="notification">
          <li class="d-flex justify-content-between news"  v-for="notification in notifications" :key="notification.id">
            <a class="news-link text-nong text-truncate" href @click="openNews(1, notification.id, $event)">{{notification.title}}</a>
            <span class="text-muted text-nowrap">[{{notification.displayDate}}]</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="block mb-3">
      <div class="block-header">
        <span class="block-header-text">优秀农产品展示</span>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div id="slider">
            <div data-u="loading" class="jssorl-009-spin">
              <img style="margin-top:-19px;position:relative;top:50%;width:38px;height:38px;" src="@/assets/spin.svg" />
            </div>
            <div data-u="slides" class="jssor-slides" id="seller-examples">
              <div data-p="30.00" v-for="seller in selectedUserList" :key="seller">
                <a :href="seller" rel="group">
                  <img :data-u="seller" :src="seller" width="200" height="150"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block mb-3" ref="pesticide">
      <div class="block-header">
        <span class="block-header-text">农产品备案查询</span>
      </div>
      <div class="table-responsive">
        <table id="table1" class="table table-bordered table-striped table-hover" style="width:100%">
          <thead style="background: #EAF2F1;">
          <tr>
            <th scope="col">产品名称</th>
            <th scope="col">产品类别</th>
            <th scope="col">生产厂家</th>
            <th scope="col">备案日期</th>
          </tr>
          </thead>
          <tbody class="text-nong">
          </tbody>
        </table>
      </div>
    </div>

    <div class="block mb-3" ref="seller">
      <div class="block-header">
        <span class="block-header-text">生产企业备案查询</span>
      </div>
      <div class="table-responsive">
        <table id="table2" class="table table-bordered table-striped table-hover" width="100%">
          <thead style="background: #EAF2F1;">
          <tr>
            <th scope="col">名称</th>
            <th scope="col">性质</th>
            <th scope="col">负责人</th>
            <th scope="col">地址</th>
            <th scope="col">备案日期</th>
          </tr>
          </thead>
          <tbody class="">
          </tbody>
        </table>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import $ from 'jquery'
window.$ = window.jQuery = $
// import 'datatables.net-dt'
// import 'datatables.net-bs4'

import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css'
import 'datatables.net-bs4/js/dataTables.bootstrap4.min'

// import '@/styles/dataTables.bootstrap4.min.css'
// import '@/vendor/dataTables.bootstrap4.min'
// import '@/vendor/datatables.min'
// import '@/vendor/datatables.min.css'

import ('@fancyapps/fancybox/dist/jquery.fancybox.min')
import ('@fancyapps/fancybox/dist/jquery.fancybox.min.css')

let options = {
  // https://www.jssor.com/development/api-options.html
  $AutoPlay: 1,
  $Idle: 0,
  $SlideDuration: 5000,
  // $SlideEasing: $Jease$.$Linear,
  $PauseOnHover: 4,
  $SlideWidth: 200,
  $SlideHeight: 150,
  // $Cols: 7,
  $Align: 0,
  // $FillMode: 2
  // $SlideSpacing: 4
}
let jssor_slider = null

export default {
  name: "GovHomePage",
  // components: {
  //   CoolLightBox,
  // },
  // directives: {
  //   swiper: directive
  // },
  data: () => {
    return {
      alias: undefined,
      info: null,
      newsList: [],
      notifications: [],
      newsPictureList: [],
      selectedUserList: [],
      govWebsite: '',
      certNo: undefined,
      verifyResult: -1,
      verifyNumber: 0,
      isMobile: false
    }
  },
  // computed: {
  //   swiper() {
  //     return this.$refs.mySwiper.$swiper
  //   }
  // },
  created() {
    this.alias = this.$route.params.alias
    this.isMobile = this.isMobileDevice()
  },
  mounted() {
    $.fn.DataTable.ext.pager.numbers_length = 5;
    console.log($.fn.dataTable.defaults)
    $.extend(true, $.fn.dataTable.defaults, {
      "scrollX": true,
      "processing": true,
      "serverSide": true,
      // "searching": false,
      "ordering": false,
      // "dom": "<'row'<'col-sm-12'tr>><'row'<'col-sm-2'l><'col-sm-5'><'col-sm-1'i><'col-sm-4'p>>",
      // dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      // dom: "<'row " + (this.isMobile ? 'mobile' : '') + "'<'col-sm-12'f>><'row mb-2'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      language: {
        "sProcessing": "<h3>处理中...</h3>",
        "sLengthMenu": "显示 _MENU_ 项结果",
        "sZeroRecords": "没有匹配结果",
        "sInfo": "第 _START_ 至 _END_ 项，共 _TOTAL_ 项",
        "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
        "sInfoFiltered": "",
        // "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
        "sInfoPostFix": "",
        "sSearch": "搜索:",
        "sUrl": "",
        "sEmptyTable": "表中数据为空",
        "sLoadingRecords": "载入中...",
        "sInfoThousands": ",",
        "oPaginate": {
          "sFirst": "首页",
          "sPrevious": "上页",
          "sNext": "下页",
          "sLast": "末页"
        },
        "oAria": {
          "sSortAscending": ": 以升序排列此列",
          "sSortDescending": ": 以降序排列此列"
        }
      }
    })
    if (this.isMobile) {
      $.extend(true, $.fn.dataTable.defaults, {
        dom: "<'row mobile'<'col-sm-12'f>><'row mb-2'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>"
      })
    } else {
      $.extend(true, $.fn.dataTable.defaults, {
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'row mb-2'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>"
      })
    }

    this.getInfo()
    // console.log(this.alias)
  },
  methods: {
    init: function() {
    },
    getInfo: function() {
      axios.get(this.$BASE_URL + '/web/govsite/list?url=' + this.alias).then(res => {
        if (res.data.data.length) {
          this.info = res.data.data[0]
          this.$emit('updateInfo', this.info)
          this.getData(this.info)
          console.log(this.info)

          $('#table1').DataTable({
            "ajax": {
              "url": this.$BASE_URL + '/web/product/area/list' + this.getLocationUrl(),
            },
            // "data": function(d) {
            //   d.register = 1
            // },
            "columns": [
              {
                "data": "name"
              },
              {
                "data": "categoryName"
              },
              {
                "data": "companyName",
                "searchable": false
              },
              {
                "data": "createTime",
                "searchable": false,
                render: data => {
                  if (data) {
                    return this.formatTimestamp(data)
                  } else {
                    return 'N/A'
                  }
                }
              }
            ]
          })

          $('#table2').DataTable({
            "ajax": {
              "url": this.$BASE_URL + '/web/licenseauth/list' + this.getLocationUrl(),
            },
            "columns": [
              {
                "data": null,
                render: function(data, type, row) {
                  return row.userType ? row.companyName : row.contact
                }
              },
              {
                "data": null,
                render: function(data, type, row) {
                  return row.userType ? '企业' : '个人'
                }
              },
              {
                "data": 'contact'
              },
              {
                "data": "address",
                "searchable": false,
                render: function(data, type, row) {
                  return (row.province || '') + (row.city || '') + (row.town || '') + (data || '')
                }
              },
              {
                "data": "createTime",
                "searchable": false,
                render: data => {
                  return this.formatTimestamp(data)
                }
              }
            ]
          })
        }
      })
    },
    getData: function(info) {
      axios.get(this.$BASE_URL + '/web/news/list', {
        params: {
          province: info.province,
          city: info.city,
          town: info.town
        }
      }).then(res => {
        // console.log(res.data.data)
        // let that = this

        let newsList = res.data.data.newsList.slice(0, 7)

        if (newsList.length === 0) {
          axios.get(this.$BASE_URL + '/web/news/default').then(res => {
            // console.log('axios default', res.data.data)
            newsList = res.data.data.newsList
            newsList.forEach(news => {
              news.displayDate = this.formatTimestamp(news.date)
            })
            this.newsList = newsList

            // res.data.data.noticeList.forEach(notification => {
            //   notification.displayDate = this.formatTimestamp(notification.date)
            // })
            // this.notifications = res.data.data.noticeList

            let newsPictureList = []
            res.data.data.newsPictureList.forEach(pic => {
              newsPictureList.push(this.$UPLOAD_PREFIX + pic)
            })
            this.newsPictureList = newsPictureList

            let selectedUserList = []
            res.data.data.selectedUserList.forEach(pic => {
              selectedUserList.push(this.$UPLOAD_PREFIX + pic)
            })
            this.selectedUserList = selectedUserList

            window.setTimeout(() => {
              // eslint-disable-next-line no-undef
              jssor_slider = new $JssorSlider$("slider", options)
              this.scaleSlider()
              $('#slider').find('.jssor-slides a').fancybox()
              $(window).bind("load", this.scaleSlider())
              $(window).bind("resize", this.scaleSlider())
              $(window).bind("orientationchange", this.scaleSlider())
            }, 30)
          }).catch(function (error) {
            console.log('axios error', error)
          })
        } else {
          newsList.forEach(news => {
            news.displayDate = this.formatTimestamp(news.date)
          })
          this.newsList = newsList

          let newsPictureList = []
          res.data.data.newsPictureList.forEach(pic => {
            newsPictureList.push(this.$UPLOAD_PREFIX + pic)
          })
          // if (newsPictureList.length === 0) {
          //   newsPictureList = [
          //     'http://via.placeholder.com/700x300',
          //     'http://via.placeholder.com/700x300/2B628B/333',
          //     'http://via.placeholder.com/700x300/00aba9/666'
          //   ]
          // }
          this.newsPictureList = newsPictureList
          // console.log('pic', that.newsPictureList)

          let selectedUserList = []
          res.data.data.selectedUserList.forEach(pic => {
            selectedUserList.push(this.$UPLOAD_PREFIX + pic)
          })
          this.selectedUserList = selectedUserList

          window.setTimeout(() => {
            // eslint-disable-next-line no-undef
            jssor_slider = new $JssorSlider$("slider", options)
            this.scaleSlider()
            $('#slider').find('.jssor-slides a').fancybox()
            $(window).bind("load", this.scaleSlider())
            $(window).bind("resize", this.scaleSlider())
            $(window).bind("orientationchange", this.scaleSlider())
          }, 30)
        }
      })

      axios.get(this.$BASE_URL + '/web/notification/area/list' + this.getLocationUrl()).then(res => {
        let notifications = res.data.data.slice(0, 7)
        if (notifications.length === 0) {
          axios.get(this.$BASE_URL + '/web/notification/area/list?province=东龙').then(res => {
            // console.log('notification default', res.data)
            notifications = res.data.data.slice(0, 7)
            notifications.forEach(notification => {
              notification.displayDate = this.formatTimestamp(notification.createTime)
            })
            this.notifications = notifications
          })
        } else {
          notifications.forEach(notification => {
            notification.displayDate = this.formatTimestamp(notification.createTime)
          })
          this.notifications = notifications
        }
      })
    },
    openNews: function(type, id, event) {
      this.$router.push({
        name: 'News',
        params: {
          type: type,
          id: id
        }})

      event.preventDefault()
    },
    verify: function() {
      axios.get(this.$BASE_URL + '/web/cert/verify?cid=' + this.certNo).then(res => {
        // console.log(res.data)
        if (res.data.data) {
          this.verifyResult = 1
          this.verifyNumber = res.data.data.scanNum
        } else {
          this.verifyResult = 0
          this.verifyNumber = 0
        }
      })
    },

    getLocationUrl: function() {
      var info = this.info
      var url = '?'

      if (info.province) {
        url += 'province=' + info.province + '&'
      }
      if (info.city) {
        url += 'city=' + info.city + '&'
      }
      if (info.town) {
        url += 'town=' + info.town
      }

      return url
    },
    formatTimestamp: function (timestamp) {
      var a = new Date(timestamp)
      var year = a.getFullYear()
      var month = a.getMonth() + 1
      var date = a.getDate()

      return year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date)
    },
    scaleSlider: function () {
      // console.log('scaleSlider')
      var containerElement = jssor_slider.$Elmt.parentNode;
      var containerWidth = containerElement.clientWidth;

      if (containerWidth) {
        var expectedWidth = Math.min(containerWidth, containerWidth) - 30;
        // var expectedWidth = Math.min(MAX_WIDTH || containerWidth, containerWidth);
        jssor_slider.$ScaleWidth(expectedWidth);
      }
      else {
        window.setTimeout(this.scaleSlider, 30);
      }
    },
    scrollTo(refName, event) {
      var element = this.$refs[refName]
      var top = element.offsetTop

      window.scrollTo(0, top)

      event.preventDefault()
    },
    isMobileDevice: function() {
      // console.log(window.innerWidth, window.innerHeight)
      // return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 600 ) )
      console.log(navigator.userAgent)
      return ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) )
    }
  }
}
</script>

<style scoped lang="scss">
#qr {
  background: url('../assets/qr_bg.png');
}

.list-unstyled {
  margin-bottom: 0;
  li {
    padding-left: 10px !important;
  }
}

.verification-container {
  background: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
}

.jssorl-009-spin img {
  animation-name: jssorl-009-spin;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes jssorl-009-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#slider {
  position:relative;
  margin:0 auto;
  top:0px;
  left:0px;
  /* width:90%; */
  width:980px;
  height:150px;
  overflow:hidden;
  visibility:hidden;
}

#slider .jssorl-009-spin {
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  text-align:center;
  background-color:rgba(0,0,0,0.7);
}

#slider .jssor-slides {
  cursor:default;
  position:relative;
  top:0px;
  left:0px;
  /* width:100%; */
  width:980px;
  height:150px;
  overflow:hidden;
}

a:hover {
  color: #ffa313;
}

li.news {
  padding-left: 15px;
  //background: url(img/dk2.jpg) no-repeat left;
}
</style>

<style lang="scss">
div.dataTables_wrapper .row.mobile div.dataTables_filter label {
  width: 100%;
}
div.dataTables_wrapper .row div.dataTables_filter label {
  width: auto;
}
div.dataTables_wrapper .row.mobile div.dataTables_filter input {
  width: calc(100% - 44px);
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
  padding-right: 0 !important;
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
  padding-left: 0;
}

// 非要画出个滚动条。。。
.dataTables_scrollBody::-webkit-scrollbar {
  -webkit-appearance: none;
}
.dataTables_scrollBody::-webkit-scrollbar:vertical {
  width: 12px;
}
.dataTables_scrollBody::-webkit-scrollbar:horizontal {
  height: 12px;
}
.dataTables_scrollBody::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}
.dataTables_scrollBody::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}
</style>
